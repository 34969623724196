import mqtt from 'mqtt';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { React, useEffect, useState } from 'react';
import Switch from 'react-switch';
import haversine from 'haversine';
import 'leaflet-rotatedmarker';

const mqttClient = mqtt.connect('wss://developer.wscada.net:8080', { username: 'rts', password: 'rts' });

let statusTime = Date.now();
let status = 'Offline';
let logTrail = false;
let log = [];
let prev_position = {};
let filteredData = {};

const pfi = ['No position fix', 'Autonomous GNSS fix', 'Differential GNSS fix', '', 'RTK fixed', 'RTK float', 'Dead reckoning fix'];

const toDD = (coordX, coordY, precision = 7) => {
    const precisionPosition = precision + 2;
    const coordXDDF = parseInt(coordX/100);
    const coordXDDB = parseFloat((((coordX/100) % 1) / 60) * 100).toFixed(11).slice(1, precisionPosition);
    const coordYDDF = parseInt(coordY/100);
    const coordYDDB = parseFloat((((coordY/100) % 1) / 60) * 100).toFixed(11).slice(1, precisionPosition);

    return [(coordXDDF + coordXDDB), (coordYDDF + coordYDDB)];
}

let lat = [];
let long = [];
let alt = []

let counter = 0;
let firstrun = true;
// const noofsamples = 60;
const noofsamples = 1;

const stations = {}
let udi = null;

let dist_udi1 = null;
let dist_udi2 = null;
let c_udi = null;

const markerSvgString =
      '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\t viewBox="0 0 488.98 488.98" style="enable-background:new 0 0 488.98 488.98;" xml:space="preserve"><g>\t<g>\t\t<path d="M409.49,485.75l-165-80.9l-165,80.9c-25.3,12.4-52.4-13.1-41.6-39.1l178.5-427.9c10.4-25,45.8-25,56.3,0l178.4,427.9\t\t\tC461.89,472.55,434.79,498.15,409.49,485.75z"/>\t</g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>';

const markerUrl = encodeURI("data:image/svg+xml," + markerSvgString).replace(
    "#",
    "%23"
);

const markerIcon = window.L?.icon({
    iconUrl: markerUrl,
    iconSize: 30,
});

const RTKMAPS = () => {
    const [mapsPosition, setMapsPosition] = useState();
    const [switchChecked, setSwitchChecked] = useState(0);
    const [rerender, refresh] = useState(0);

    useEffect(() => {
	if (mqttClient) {
	    mqttClient.on('connect', _ => {
		console.log('connected')
		mqttClient.subscribe('rtk/rover/#');
		mqttClient.subscribe('gnss/diff');
	    });
	    
	    mqttClient.on('message', (topic, data) => {
		try {
		    statusTime = Date.now();
		    const tempPosition = JSON.parse(data);
		    // const utcTimeRaw = tempPosition['utc'].split('.')[0];
		    let [utcTimeRaw, utcTimeMilli] = tempPosition['utc'].split('.');

		    utcTimeMilli = parseInt((parseFloat(utcTimeMilli ?? 0)/10).toFixed(3) * 1000).toString();

		    tempPosition['utc'] = utcTimeRaw.slice(0,2) + ':' + utcTimeRaw.slice(2,4) + ':' + utcTimeRaw.slice(4,6) + '.' + (utcTimeMilli === '0' ? '000' : utcTimeMilli);

		    // const latTemp = (tempPosition['lat']/100).toString().split('.');
		    // tempPosition['lat'] = parseFloat(latTemp[0] + '.' + ((latTemp[1])/6).toString().substring(0, 7));

		    // const longTemp = (tempPosition['long']/100).toString().split('.');
		    // tempPosition['long'] = parseFloat(longTemp[0] + '.' + ((longTemp[1])/6).toString().substring(0, 7));


		    if (tempPosition['mode'] === "4" || tempPosition['mode'] === "2" || tempPosition['mode'] === "1") {

			[lat[counter], long[counter]] = toDD(tempPosition['lat'], tempPosition['long'], 8);
			alt[counter] = tempPosition['alt'];

			if (counter > (noofsamples - 2) && firstrun) {
			    firstrun = false;
			}
			counter++;
			counter = counter % noofsamples;

			tempPosition['lat'] = parseFloat(lat.reduce((x, y) => parseFloat(x) + parseFloat(y)))/(firstrun ? counter : noofsamples);
			tempPosition['long'] = parseFloat(long.reduce((x, y) => parseFloat(x) + parseFloat(y)))/(firstrun ? counter : noofsamples);
			tempPosition['alt'] = parseFloat(alt.reduce((x, y) => parseFloat(x) + parseFloat(y)))/(firstrun ? counter : noofsamples);

			tempPosition['lat'] = tempPosition['lat'].toFixed(10).slice(0, 11); // default 11: 1mm
			tempPosition['long'] = tempPosition['long'].toFixed(10).slice(0, 11); // default 11: 1mm
			tempPosition['alt'] = tempPosition['alt'].toFixed(5).slice(0, 8);

			tempPosition['speed'] = tempPosition['speed'] > 0.04 ? tempPosition['speed'] : 0;

			udi = topic.split('/').slice(-1);
			stations[udi] = { udi, ...tempPosition };
		    }
		    

		    // [tempPosition['lat'], tempPosition['long']] = toDD(tempPosition['lat'], tempPosition['long'], 8);
		    
		    if (prev_position['lat'] !== tempPosition['lat']
			|| prev_position['long'] !== tempPosition['long']) {
			
			prev_position['lat'] = tempPosition['lat'];
			prev_position['long'] = tempPosition['long'];
			
			// if (logTrail && (tempPosition.speed > 0.5)) {
			if (logTrail) {
			    log.push({
				lat: tempPosition.lat,
				long: tempPosition.long,
				heading: tempPosition.heading
			    })
			}
		    }

		    if (c_udi == udi) {
			if (stations[udi]) {
			    filteredData = stations[udi];
			}
		    }

		    setMapsPosition(stations[udi]);
		} catch (error) {
		    console.log(error);
		}
	    });

	    setInterval(() => {
		if ((Date.now() - statusTime) > 3000) { // in milliseconds
		    status = 'Offline';
		    refresh(1);
		} else {
		    status = 'Online';
		    refresh(1);
		}
	    }, 1000)
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (mapsPosition) {
	return (
	    <div className='mapPage'>
		<div className='body'>
		    <MapContainer foo={rerender} center={[ mapsPosition['lat'], mapsPosition['long'] ]} zoom={22} attributionControl={true} scrollWheelZoom={true} className='map-container'>
			<TileLayer maxZoom={30} maxNativeZoom={19}
				   foo={rerender}
				   attribution="Real Time Solutions (Hardware Division): RTK GNSS"
				   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			{
			    logTrail ?
				log.map((pos, i) => (
				    <Marker key={i} position={[ pos['lat'], pos['long'] ]}
					    icon={markerIcon}
					    rotationAngle={pos['heading']}
					    rotationOrigin={'center'}
				    />
				))
				:
				Object.keys(stations).map(station => (
				    <Marker
					key={station}
					foo={rerender}
					position={[ stations[station]['lat'], stations[station]['long'] ]}
					icon={markerIcon}
					rotationAngle={stations[station]['heading']}
					rotationOrigin={'center'}
				    >
					<Popup>
					    <div>
						{station} 
					    </div>
					    <div>
						{stations[station]['lat']}, {stations[station]['long']}
					    </div>
					    <div>
						{stations[station]['utc']}
					    </div>
					</Popup>
				    </Marker>
				))
			}
		    </MapContainer>
		    <div className='data-container'>
			<div className='data'>
			    <h4>UDI:
				<select style={{ marginLeft: 4, marginRight: 2 }} onChange={(event) => c_udi = event.target.value}>
				    <option value={null}>None</option>
				    {Object.keys(stations).map(station => (<option value={stations[station]['udi']}>{stations[station]['udi']}</option>))}
				</select>
			    </h4>
			    <h4>Status: {status}</h4>
			    <h4>UTC: {filteredData['utc']}</h4>
			    <h4>Latitude: {filteredData['lat']}</h4>
			    <h4>Longitude: {filteredData['long']}</h4>
			    <h4>Altitude (Orthometric (MSL), H): {filteredData['alt']}</h4>
			    <h4>Altitude (Ellipsoid, h): {(parseFloat(filteredData['alt']) + parseFloat(filteredData['geoSep'])).toFixed(3)}</h4>
			    <h4>Geoid Sep (N): {parseFloat(filteredData['geoSep']).toFixed(3)}</h4>
			    <h4>Mode: {pfi[filteredData['mode']]}</h4>
			    <h4>Satellites: {filteredData['sat']}</h4>
			    <h4>Hdop: {filteredData['hdop']}</h4>
			    <h4>Heading: {filteredData['heading']}</h4>
				{ /* <h4>Speed: {(parseFloat(filteredData['speed']) * 1.852).toFixed(4)} km/hr</h4> */ }
			    <h4>Speed: {parseFloat(filteredData['speed']).toFixed(4)} km/hr</h4>
			    <h4>Battery Voltage: {filteredData['batt']} mV</h4>
			    <div className='ref-image'>
				<img src="/gd.jpg" />
			    </div>
			</div>
		    </div>
		</div>
		{ /*<div className='footer'>
		    <h3 style={{ marginRight: 10 }}>Trail</h3>
		    <Switch
		    onChange={() => {
		    logTrail = switchChecked ^ 1;
		    if (logTrail) {
		    log.push({
		    lat: mapsPosition.lat,
		    long: mapsPosition.long
		    });
		    } else {
		    log = [];
		    }
		    setSwitchChecked(logTrail);
		    }}
		    checked={switchChecked ? true : false}
		    id="trailSwitch"
		    height={28}
		    width={56}
		    />
		    </div> */}
		<div className='footer'>
		    <div style={{ display: 'flex' }}>
			<h3 style={{ marginRight: 10 }}>Trail</h3>
			<div style={{ display: 'flex' }}>
			    <Switch
				id="trailSwitch"
				height={18}
				width={36}
				checked={switchChecked ? true : false}
				onChange={() => {
				    logTrail = switchChecked ^ 1;
				    if (logTrail) {
					log.push({
					    lat: mapsPosition.lat,
					    long: mapsPosition.long,
					    heading: mapsPosition.heading
					});
				    } else {
					log = [];
				    }
				    setSwitchChecked(logTrail);
				}}
			    />
			</div>
		    </div>
		    <select style={{ marginRight: 3 }} onChange={(event) => dist_udi1 = event.target.value}>
			<option value={null}>None</option>
			{Object.keys(stations).map(station => (<option value={stations[station]['udi']}>{stations[station]['udi']}</option>))}
		    </select>
		    {"<->"}
		    <select style={{ marginLeft: 4, marginRight: 2 }} onChange={(event) => dist_udi2 = event.target.value}>
			<option value={null}>None</option>
			{Object.keys(stations).map(station => (<option value={stations[station]['udi']}>{stations[station]['udi']}</option>))}
		    </select>
		    {
			(dist_udi1 !== null && dist_udi2 !== null) ? (
			    ": " +
				parseInt(haversine({
				    latitude: stations[dist_udi1]['lat'],
				    longitude: stations[dist_udi1]['long']
				},
						   {
						       latitude: stations[dist_udi2]['lat'],
						       longitude: stations[dist_udi2]['long']
						   }, { unit: 'meter' }) * 1000) + " mm"
			) : ""
		    }
		</div>
	    </div>
	)
    } else {
	return (
	    <div className='offline'>
		<h1>GNSS system is offline</h1>
	    </div>
	)
    }
}
// <h3>Precision: {firstrun ? 'Calibrating' : 'Calibrated'}</h3>

export default RTKMAPS;
